import React, { useEffect, useState } from "react";
import { doc, onSnapshot } from "firebase/firestore";
import { db } from "../../firebase"; // Adjust the path to your firebase.js
import "./Header.css";

const Header = () => {
  const [schedule, setSchedule] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const docRef = doc(db, "freeDelivery", "header"); // Adjust collection and document names

    // Use onSnapshot for real-time updates
    const unsubscribe = onSnapshot(
      docRef,
      (docSnap) => {
        if (docSnap.exists()) {
          const data = docSnap.data();
          setSchedule(data);
        } else {
          console.log("No such document!");
          setSchedule({});
        }
        setLoading(false);
      },
      (error) => {
        console.error("Error fetching real-time delivery schedule:", error);
      }
    );

    // Cleanup listener on component unmount
    return () => unsubscribe();
  }, []);

  // Define the order of weekdays
  const weekdayOrder = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <header className="header-info">
      <h3>Free Delivery</h3>
      <p className="delivery-schedule">
        {weekdayOrder
          .filter((day) => schedule[day]) // Include only days present in the schedule
          .map((day) => (
            <span key={day}>
              <strong>{day}:</strong> {schedule[day]} |{" "}
            </span>
          ))}
      </p>
    </header>
  );
};

export default Header;
