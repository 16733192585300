
const EmailBody = ({ orderid, name, twoQty, fiveQty, twoPrice, fivePrice, twoTotal, fiveTotal, amount }
) => {

    if (twoQty === 0) {
        twoPrice = 0;
        twoTotal = 0;
    } else if (fiveQty === 0) {
        fivePrice = 0;
        fiveTotal = 0;
    }

    return (
        <div style={{
            maxWidth: "600px",
            margin: "20px auto",
            background: "#ffffff",
            padding: "20px",
            borderRadius: "8px",
            boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
        }}>
            {/* Header */}
            <div style={{
                textAlign: "center",
                padding: "10px 0",
                borderBottom: "2px solid #4CAF50",
            }}>
                <img
                    style={{
                        height: "140px",
                        width: "190px",
                    }}
                    src="https://raw.githubusercontent.com/ArunAkilan/nalanlogo/master/logo.png"
                    alt="Company Logo"
                />
                <h1 style={{
                    margin: "0",
                    fontSize: "24px",
                    color: "#4CAF50",
                }}>Order Confirmation</h1>
                <p>Thank you for your purchase!</p>
            </div>

            {/* Content */}
            <div style={{
                padding: "20px 0",
            }}>
                <h2 style={{
                    color: "#333",
                    fontSize: "20px",
                }}>Hi, {name}</h2>
                <p style={{
                    fontSize: "16px",
                    color: "#555",
                    lineHeight: "1.6",
                }}>
                    Your order has been successfully placed! Here are the details of your purchase:
                </p>
            </div>

            {/* Order Summary */}
            <div style={{
                margin: "20px 0",
            }}>
                <h2 style={{
                    color: "#333",
                    fontSize: "15px",
                }}>Order ID : {orderid}</h2>
                <h2 style={{
                    color: "#333",
                    fontSize: "20px",
                }}>Order Summary</h2>
                <table style={{
                    width: "100%",
                    borderCollapse: "collapse",
                    marginBottom: "10px",
                }}>
                    <thead>
                        <tr>
                            <th style={{
                                padding: "10px",
                                textAlign: "left",
                                border: "1px solid #ddd",
                                backgroundColor: "#4CAF50",
                                color: "white",
                            }}>Item</th>
                            <th style={{
                                padding: "10px",
                                textAlign: "left",
                                border: "1px solid #ddd",
                                backgroundColor: "#4CAF50",
                                color: "white",
                            }}>Quantity</th>
                            <th style={{
                                padding: "10px",
                                textAlign: "left",
                                border: "1px solid #ddd",
                                backgroundColor: "#4CAF50",
                                color: "white",
                            }}>Price</th>
                            <th style={{
                                padding: "10px",
                                textAlign: "left",
                                border: "1px solid #ddd",
                                backgroundColor: "#4CAF50",
                                color: "white",
                            }}>Total</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td style={{
                                padding: "10px",
                                textAlign: "left",
                                border: "1px solid #ddd",
                            }}>2.5 Liters</td>
                            <td style={{
                                padding: "10px",
                                textAlign: "left",
                                border: "1px solid #ddd",
                            }}>{twoQty}</td>
                            <td style={{
                                padding: "10px",
                                textAlign: "left",
                                border: "1px solid #ddd",
                            }}>&#36;{twoPrice}</td>
                            <td style={{
                                padding: "10px",
                                textAlign: "left",
                                border: "1px solid #ddd",
                            }}>&#36;{twoTotal}</td>
                        </tr>
                        <tr>
                            <td style={{
                                padding: "10px",
                                textAlign: "left",
                                border: "1px solid #ddd",
                            }}>5 Liters</td>
                            <td style={{
                                padding: "10px",
                                textAlign: "left",
                                border: "1px solid #ddd",
                            }}>{fiveQty}</td>
                            <td style={{
                                padding: "10px",
                                textAlign: "left",
                                border: "1px solid #ddd",
                            }}>&#36;{fivePrice}</td>
                            <td style={{
                                padding: "10px",
                                textAlign: "left",
                                border: "1px solid #ddd",
                            }}>&#36;{fiveTotal}</td>
                        </tr>
                    </tbody>
                    <tfoot>
                        <tr style={{
                            fontWeight: "bold",
                        }}>
                            <td colSpan="3" style={{
                                padding: "10px",
                                textAlign: "left",
                                border: "1px solid #ddd",
                            }}>Grand Total</td>
                            <td style={{
                                padding: "10px",
                                textAlign: "left",
                                border: "1px solid #ddd",
                            }}>{amount}</td>
                        </tr>
                    </tfoot>
                </table>
            </div>

            {/* Footer */}
            <div style={{
                textAlign: "center",
                marginTop: "20px",
                fontSize: "14px",
                color: "#777",
            }}>
                <p>
                    Need help? Contact our support team at{" "}
                    <a style={{
                        color: "#4CAF50",
                        textDecoration: "none",
                    }} href="mailto:nalanorderinfo@gmail.com">nalanorderinfo@gmail.com</a>.
                </p>
                <p>
                    Thank you for shopping with us! <br />
                    <a style={{
                        color: "#4CAF50",
                        textDecoration: "none",
                    }} href="https://nalanbatters.ca">Visit our website</a>
                </p>
            </div>
        </div>
    );
}



export default EmailBody