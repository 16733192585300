import React, { useEffect, useState, useRef } from "react";
import {
  collection,
  onSnapshot,
  updateDoc,
  doc,
  deleteDoc,
  getDocs,
} from "firebase/firestore";
import { db } from "../../firebase";
import "./Notification.css";

export default function Notification() {
  const [notifications, setNotifications] = useState([]);
  const [badgeCount, setBadgeCount] = useState(0);
  const [isNotificationVisible, setIsNotificationVisible] = useState(false);

  const popupRef = useRef(null); // Reference for the popup
  const iconRef = useRef(null); // Reference for the notification icon

  // Fetch notifications in real-time
  useEffect(() => {
    const unsubscribe = onSnapshot(collection(db, "notification"), (snapshot) => {
      const notificationsData = snapshot.docs.map((doc) => {
        const data = doc.data();
        const dateObj = data.dateDay?.toDate(); // Convert Firestore Timestamp to JS Date
        const formattedDate = formatDate(dateObj);

        return {
          id: doc.id,
          ...data,
          dateDay: formattedDate || data.dateDay || "No date provided", // Fallback if no timestamp
        };
      });

      // Filter unseen notifications for the badge count
      const unseenNotifications = notificationsData.filter(
        (notification) => !notification.seen
      );
      setBadgeCount(unseenNotifications.length);

      // Sort by timestamp (newest first)
      notificationsData.sort(
        (a, b) => (b.timestamp?.seconds || 0) - (a.timestamp?.seconds || 0)
      );

      setNotifications(notificationsData);
    });

    // Cleanup the listener when the component unmounts
    return () => unsubscribe();
  }, []);

  // Close popup when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        popupRef.current &&
        !popupRef.current.contains(event.target) &&
        iconRef.current &&
        !iconRef.current.contains(event.target)
      ) {
        setIsNotificationVisible(false); // Close the popup
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // Format the date
  const formatDate = (date) => {
    const options = { year: "numeric", month: "2-digit", day: "2-digit" };
    const formattedDate = date?.toLocaleDateString("en-GB", options);
    const weekday = date?.toLocaleDateString("en-US", { weekday: "long" });
    return `${formattedDate} - ${weekday}`;
  };

  // Mark all notifications as seen when opening the notification tab
  const markAllAsSeen = async () => {
    try {
      const unseenNotifications = notifications.filter(
        (notification) => !notification.seen
      );

      const updates = unseenNotifications.map((notification) =>
        updateDoc(doc(db, "notification", notification.id), { seen: true })
      );

      await Promise.all(updates);

      setNotifications((prev) =>
        prev.map((notification) => ({ ...notification, seen: true }))
      );
      setBadgeCount(0); // Clear badge count
    } catch (error) {
      console.error("Error marking notifications as seen: ", error);
    }
  };

  // Clear all notifications
  const clearAllNotifications = async () => {
    try {
      const notificationsSnapshot = await getDocs(
        collection(db, "notification")
      );

      const deletePromises = notificationsSnapshot.docs.map((doc) =>
        deleteDoc(doc.ref)
      );
      await Promise.all(deletePromises);

      setNotifications([]); // Clear local state
      setBadgeCount(0); // Reset badge count
    } catch (error) {
      console.error("Error clearing notifications: ", error);
    }
  };

  return (
    <>
      <div className="notification-container" style={{ position: "relative" }}>
        <i
          ref={iconRef}
          className="ri-notification-3-line notification-hover"
          style={{ fontSize: "24px", cursor: "pointer" }}
          onClick={() => {
            setIsNotificationVisible((prev) => !prev); // Toggle popup visibility
            if (!isNotificationVisible) {
              markAllAsSeen(); // Mark notifications as seen only when opening
            }
          }}
        />
        {badgeCount > 0 && (
          <span
            className="badge"
            style={{
              position: "absolute",
              top: "-14px",
              right: "-14px",
              background: "red",
              color: "white",
              borderRadius: "50%",
              padding: "4px 10px",
              fontSize: "10px",
            }}
          >
            {badgeCount}
          </span>
        )}
      </div>

      {isNotificationVisible && (
        <div
          ref={popupRef}
          className="notification-popup"
          style={{
            position: "absolute",
            top: "90px",
            right: "400px",
            background: "white",
            boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
            borderRadius: "8px",
            width: "380px",
            maxHeight: "400px",
            overflowY: "auto",
            zIndex: 10,
            padding: "10px",
          }}
        >
          {notifications.length > 0 ? (
            <>
              <ul
                style={{
                  listStyle: "none",
                  padding: "0",
                  flexDirection: "column",
                  gap: "20px",
                }}
              >
                {notifications.map((notification) => (
                  <li
                    key={notification.id}
                    style={{
                      borderBottom: "1px solid #ddd",
                      padding: "2px 0",
                    }}
                  >
                    <p className="name-cloumn">{notification.orderId}</p>
                    <small>{notification.name}</small>
                    <br />
                    <small>{notification.dateDay}</small>
                    <br />
                    <small>{notification.place}</small>
                  </li>
                ))}
              </ul>

              <button
                onClick={clearAllNotifications}
                style={{
                  display: "block",
                  margin: "10px auto",
                  padding: "8px 12px",
                  background: "red",
                  color: "white",
                  border: "none",
                  borderRadius: "4px",
                  cursor: "pointer",
                }}
              >
                Clear All
              </button>
            </>
          ) : (
            <p style={{ textAlign: "center", color: "gray", height: "200px" }}>
              No notifications
            </p>
          )}
        </div>
      )}
    </>
  );
}
