import { NavLink, useNavigate } from "react-router-dom";
import logo from "../../assets/images/logo.png";
// Place all the CSS styles related to this below className in Navbar.css file
import "./Navbar.css";
import { useState } from "react";
import LoginModel from "../LoginModel/LoginModel";
import Notification from "../Notification/Notification";

export default function Navbar({ admin }) {
  const [isModelOpen, setIsModelOpen] = useState(false);
  const navigate = useNavigate();
  function modelOpen() {
    setIsModelOpen(true);
  }

  const handleLogout = () => {
    localStorage.removeItem("accessToken");
    navigate("/");
  };

  return (
    <>
      <div className="nav-container">
        <nav className="header">
          
            <div className="header-logo">
              <img src={logo} alt="nalan logo" />
            </div>
            <ul className="header-login">
              <li>
              <NavLink to="/" className={({ isActive }) => (isActive ? "nav-link active" : "nav-link")} >
                  Home
                </NavLink>
              </li>
              <li>
                {admin ? (
                  <div className="d-flex">
                    <Notification />
                    <button
                      style={{ marginLeft: "68px" }}
                      onClick={handleLogout}
                      className="login"
                    >
                      Logout
                    </button>
                  </div>
                ) : (
                  <button onClick={modelOpen} className="login">
                    Login
                  </button>
                )}
              </li>
            </ul>
          
          {isModelOpen && <LoginModel setIsModelOpen={setIsModelOpen} />}
          </nav>
      </div>

    </>
  );
}
