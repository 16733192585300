import React, { useState, useEffect } from "react";
import { db } from "../../firebase";
import { Timestamp, collection, addDoc, doc, onSnapshot } from "firebase/firestore";
import { renderToString } from "react-dom/server"; // Import renderToString at the top
import { toast } from "react-toastify"; // Toast notifications
import "./OrderInfo.css";
import EmailBody from "../EmailBody/EmailBody";
import success from "../../assets/images/Successful_purchase.gif";
import { useNavigate } from "react-router-dom";
const OrderInfo = () => {
  const [formData, setFormData] = useState({
    orderId: "",
    name: "",
    email: "",
    phone: "",
    dateDay: "",
    place: "",
    address: "",
    twoPointFiveLit: 0,
    fiveLit: 0,
    deliveryType: "",
    status: "Pending",
    amount: "",
  });

  const [step, setStep] = useState(1);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const totalSteps = 3;
  const [selectedDate, setSelectedDate] = useState("");
  const [firebaseTimestamp, setFirebaseTimestamp] = useState(null);
  const pricePerTwoPointFiveLit = 14.99; // Price for each 2.5 Ltr
  const pricePerFiveLit = 28.99; // Price for each 5 Ltr
  const [twoPointFiveLitTotal, setTwoPointFiveLitTotal] = useState(0);
  const [fiveLitTotal, setFiveLitTotal] = useState(0);
  const [filteredPlaces, setFilteredPlaces] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [dayPlaceMapping, setDayPlaceMapping] = useState({});

  const nextStep = () => setStep((prev) => Math.min(prev + 1, totalSteps));
  const prevStep = () => setStep((prev) => Math.max(prev - 1, 1));


  useEffect(() => {
    // Reference to the 'header' document in the 'freeDelivery' collection
    const docRef = doc(db, "freeDelivery", "header");

    // Real-time listener with onSnapshot
    const unsubscribe = onSnapshot(docRef, (docSnap) => {
      if (docSnap.exists()) {
        const data = docSnap.data();
        const mapping = {};

        // Iterate through each key-value pair in the document
        for (const [key, value] of Object.entries(data)) {
          // Split the value string into an array
          mapping[key] = value.split(",").map((item) => item.trim());
        }

        setDayPlaceMapping(mapping); // Update state with the processed data
      } else {
        console.log("No such document!");
      }
    });

    // Cleanup function to unsubscribe from the listener when the component unmounts
    return () => unsubscribe();
  }, []); // Empty dependency array to run only once on mount


  useEffect(() => {
    // Generate a 5-digit random number and set it as the orderId
    const randomOrderId = Math.floor(10000 + Math.random() * 90000).toString();
    setFormData((prevData) => ({
      ...prevData,
      orderId: randomOrderId,
    }));
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleIncrementDecrement = (name, change) => {
    setFormData((prevData) => {
      const updatedValue = Math.max(0, parseInt(prevData[name] || 0) + change);

      if (name === "twoPointFiveLit") {
        const updatedTwoPointFiveLitTotal =
          updatedValue * pricePerTwoPointFiveLit;
        const updatedAmount = updatedTwoPointFiveLitTotal + fiveLitTotal;
        setTwoPointFiveLitTotal(updatedTwoPointFiveLitTotal);

        return {
          ...prevData,
          [name]: updatedValue,
          amount: `$${updatedAmount.toFixed(2)}`,
        };
      }

      if (name === "fiveLit") {
        const updatedFiveLitTotal = updatedValue * pricePerFiveLit;
        const updatedAmount = twoPointFiveLitTotal + updatedFiveLitTotal;
        setFiveLitTotal(updatedFiveLitTotal);

        return {
          ...prevData,
          [name]: updatedValue,
          amount: `$${updatedAmount.toFixed(2)}`,
        };
      }

      return prevData;
    });
  };

  const handleDateChange = (e) => {
    console.log("e value : ", e.target.value);
    const date = new Date(e.target.value); // Create a JavaScript Date object
    console.log("date : ", date);
    const timestamp = Timestamp.fromDate(date); // Convert to Firebase Timestamp
    console.log("timestamp : ", timestamp);
    const dayName = date.toLocaleDateString("en-US", { weekday: "long" });

    // const dayPlaceMapping = {
    //   Thursday: ["Brampton", "Mississauga", "Etobicoke"],
    //   Friday: ["Pickering", "Whitby", "Ajax", "Oshawa"],
    //   Saturday: ["North York", "Vaughan"],
    //   Sunday: ["Scarborough", "Markham"],
    // };

    if (!dayPlaceMapping[dayName]) {
      // If the selected day is invalid, show an error
      setErrorMessage(
        "Please select a valid day (Thursday, Friday, Saturday, Sunday)."
      );
      setFilteredPlaces([]); // Clear the dropdown options
      setFormData((prevData) => ({
        ...prevData,
        dateDay: "",
        place: "", // Reset place selection
      }));
    } else {
      // Valid day, update the state
      setErrorMessage(""); // Clear any previous error
      setFilteredPlaces(dayPlaceMapping[dayName]);
      setFormData((prevData) => ({
        ...prevData,
        dateDay: timestamp,
        place: "", // Reset place selection for new date
      }));
    }

    setFirebaseTimestamp(timestamp);
  };

  const handlePlaceChange = (e) => {
    const { value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      place: value,
    }));
  };

  const handlePaymentChange = (e) => {
    setFormData({ ...formData, deliveryType: e.target.value });
  };

  // form validation
  const validateFormData = () => {
    // Ensure required fields are filled
    const requiredFields = [
      "name",
      "email",
      "phone",
      "dateDay",
      "place",
      "address",
      "deliveryType",
    ];
    for (const field of requiredFields) {
      if (!formData[field]) {
        alert(`Please fill out the ${field} field.`);
        return false;
      }
    }
    return true;
  };
  const submitForm = async () => {
    if (!validateFormData()) return; // Stop submission if validation fails

    // Set the button state to submitting
    setIsSubmitting(true);

    try {
      // Save form data to Firestore
      const docRef = await addDoc(collection(db, "userOrders"), {
        ...formData,
      });
      console.log("User data added to Firestore with ID: ", docRef.id);

      toast.success("Form submitted successfully!");
      console.log("Submitted Data:", { ...formData }); // Log submitted data

      /* START SEND MAIL TO USER */
      try {
        // Convert the template to HTML string
        const userName = formData.name.toUpperCase();
        const htmlBody = renderToString(
          <EmailBody
          orderid={formData.orderId}
          name={userName}
          twoQty={formData.twoPointFiveLit}
          fiveQty={formData.fiveLit}
          twoPrice={pricePerTwoPointFiveLit}
          fivePrice={pricePerFiveLit}
          twoTotal={twoPointFiveLitTotal}
          fiveTotal={fiveLitTotal}
          amount={formData.amount}
          />
        );

        // Add the document to Firestore
        await addDoc(collection(db, "mail"), {
          to: formData.email,
          cc: "nalanorderinfo@gmail.com",
          message: {
            subject: "Order Confirmation",
            text: "order conformation mail",
            html: htmlBody,
          },
        });

        // alert("Email scheduled successfully!");
      } catch (error) {
        console.error("Error scheduling email: ", error);
        alert("Failed to schedule email");
      }
      /* END SEND MAIL TO USER */

      /* START NOTIFICATION COLLECTION */

      try {
        const notificationData = {
          orderId: formData.orderId,
          name: formData.name,
          place: formData.place,
          dateDay: formData.dateDay,
        };
        const docRef = await addDoc(collection(db, "notification"), {
          ...notificationData,
        });
        console.log(
          "notification data added to Firestore with ID: ",
          docRef.id
        );
      } catch (error) {
        console.error("Error adding notification data to Firestore: ", error);
        alert("Failed to add notification data. Please try again.");
      }

      /* START NOTIFICATION COLLECTION */

      // Reset form and step after successful submission
      const randomOrderId = Math.floor(
        10000 + Math.random() * 90000
      ).toString();

      setFormData({
        orderId: randomOrderId,
        name: "",
        email: "",
        phone: "",
        dateDay: "",
        place: "",
        address: "",
        twoPointFiveLit: "",
        fiveLit: "",
        deliveryType: "",
        status: "",
        amount: "",
      });
      setStep(3);
    } catch (error) {
      console.error("Error adding user data to Firestore: ", error);
      alert("Failed to submit form. Please try again.");
    }
    finally {
      setIsSubmitting(false); // Re-enable the button after submission is complete
    }
  };
  // form fields

  const renderStep = () => {
    if (step === 1) {
      return (
        <>
          {<h2>Order Info</h2>
          /* <label>Order Id</label>
          <br />
          <input
            type="text"
            name="orderId"
            placeholder="Order Id"
            value={formData.orderId}
            onChange={handleChange}
            readOnly
          /> */}
          <br />
          <label>Name</label>
          <br />
          <input
            type="text"
            name="name"
            placeholder="User Name"
            value={formData.name}
            onChange={handleChange}
          />
          <br />
          <label>Email</label>
          <br />
          <input
            type="email"
            name="email"
            placeholder="Email"
            value={formData.email}
            onChange={handleChange}
          />
          <br />
          <label>Contact No</label>
          <br />
          <input
            type="tel"
            name="phone"
            placeholder="Contact No"
            pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}"
            value={formData.phone}
            onChange={handleChange}
          />
          <br />
          <div className="day-place">
            <div>
              <label>Date</label>
              <br />
              <input
                type="date"
                name="dateDay"
                min={new Date().toISOString().split('T')[0]} 
                value={selectedDate}
                // onChange={handleDateChange}
                onChange={(e) => {
                  setSelectedDate(e.target.value);
                  handleDateChange(e);
                }}
              />
              <br />
              {errorMessage && (
                <span style={{ color: "red" }}>{errorMessage}</span>
              )}
            </div>
            <div>
              <label>Place</label>
              <br />
              <select
                name="place"
                value={formData.place}
                onChange={handlePlaceChange}
              >
                <option value="" disabled>
                  Select Place
                </option>
                {filteredPlaces.map((place) => (
                  <option key={place} value={place}>
                    {place}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <button className="btn" style={{ float: "right" }} onClick={nextStep}>
            <i class="ri-arrow-right-line"></i>
          </button>
          <div style={{ marginTop: "30px" }}></div>
        </>
      );
    }

    if (step === 2) {
      return (
        <div>
          <h2>Order Info</h2>

          <label>Address</label>
          <br />
          <input className="text-area"
            placeholder="Address"
            name="address"
            value={formData.address}
            onChange={handleChange}
          ></input>
          <br />
          <div className="ltrs">
            <div>
              <label>2.5 Ltr</label>
              <br />
              <div
                style={{ display: "flex", alignItems: "center", gap: "10px" }}
              >
                {/* Decrement Button */}
                <button
                  type="button"
                  onClick={() =>
                    handleIncrementDecrement("twoPointFiveLit", -1)
                  }
                  style={{
                    backgroundColor: "#3c8238",
                    color: "white",
                    padding: "5px 15px",
                    border: "none",
                    borderRadius: "5px",
                    cursor: "pointer",
                  }}
                >
                  -
                </button>

                {/* Number Input */}
                <input
                  type="number"
                  name="twoPointFiveLit"
                  placeholder="0"
                  value={formData.twoPointFiveLit}
                  readOnly
                  style={{
                    width: "50px",
                    textAlign: "center",
                    border: "1px solid #ccc",
                    borderRadius: "5px",
                  }}
                />

                {/* Increment Button */}
                <button
                  type="button"
                  onClick={() => handleIncrementDecrement("twoPointFiveLit", 1)}
                  style={{
                    backgroundColor: "#3c8238",
                    color: "white",
                    padding: "5px 15px",
                    border: "none",
                    borderRadius: "5px",
                    cursor: "pointer",
                  }}
                >
                  +
                </button>
                <span
                  style={{
                    backgroundColor: "#3c8238",
                    color: "white",
                    padding: "5px 15px",
                    borderRadius: "5px",
                    fontWeight: "bold",
                  }}
                >
                  ${twoPointFiveLitTotal || 0}
                </span>
              </div>

              <br />
            </div>

            <div>
              <label>5 Ltr</label>
              <br />
              <div
                style={{ display: "flex", alignItems: "center", gap: "10px" }}
              >
                {/* Decrement Button */}
                <button
                  type="button"
                  onClick={() => handleIncrementDecrement("fiveLit", -1)}
                  style={{
                    backgroundColor: "#3c8238",
                    color: "white",
                    padding: "5px 15px",
                    border: "none",
                    borderRadius: "5px",
                    cursor: "pointer",
                  }}
                >
                  -
                </button>

                {/* Number Input */}
                <input
                  type="number"
                  name="fiveLit"
                  placeholder="0"
                  value={formData.fiveLit}
                  readOnly
                  style={{
                    width: "50px",
                    textAlign: "center",
                    border: "1px solid #ccc",
                    borderRadius: "5px",
                  }}
                />

                {/* Increment Button */}
                <button
                  type="button"
                  onClick={() => handleIncrementDecrement("fiveLit", 1)}
                  style={{
                    backgroundColor: "#3c8238",
                    color: "white",
                    padding: "5px 15px",
                    border: "none",
                    borderRadius: "5px",
                    cursor: "pointer",
                  }}
                >
                  +
                </button>
                <span
                  style={{
                    backgroundColor: "#3c8238",
                    color: "white",
                    padding: "5px 15px",
                    borderRadius: "5px",
                    fontWeight: "bold",
                  }}
                >
                  ${fiveLitTotal || 0}
                </span>
              </div>

              <br />
            </div>
          </div>

          {/* Payment Method */}
          <div style={{ marginBottom: "20px" }}>
            <label>Payment Method</label>
            <br />
            <div className="payment">
              <div>
                <label
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginTop: "10px",
                    gap: "10px",
                  }}
                >
                  <input
                    type="radio"
                    name="deliveryType"
                    value="Cash on Delivery"
                    checked={formData.deliveryType === "Cash on Delivery"}
                    onChange={handlePaymentChange}
                    style={{
                      appearance: "none",
                      border: "2px solid #F75506",
                      borderRadius: "50%",
                      width: "20px",
                      height: "20px",
                      outline: "none",
                      cursor: "pointer",
                      backgroundColor:
                        formData.deliveryType === "Cash on Delivery"
                          ? "#F75506"
                          : "white",
                    }}
                  />
                  Cash on Delivery
                </label>
              </div>

              <div>
                <label
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginTop: "10px",
                    gap: "10px",
                  }}
                >
                  <input
                    type="radio"
                    name="deliveryType"
                    value="Online Payment"
                    checked={formData.deliveryType === "Online Payment"}
                    onChange={handlePaymentChange}
                    style={{
                      appearance: "none",
                      border: "2px solid #F75506",
                      borderRadius: "50%",
                      width: "20px",
                      height: "20px",
                      outline: "none",
                      cursor: "pointer",
                      backgroundColor:
                        formData.deliveryType === "Online Payment"
                          ? "#F75506"
                          : "white",
                    }}
                  />
                  Online Payment
                </label>
              </div>
            </div>
          </div>
          {/* Navigation Buttons */}
          <button className="btn" onClick={prevStep} style={{ float: "right" }}>
            <i class="ri-arrow-left-line"></i>
          </button>
          <div
            style={{
              marginTop: "50px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <button
              type="submit"
              onClick={submitForm}
              disabled={isSubmitting}
              style={{
                padding: "20px 30px",
                backgroundColor: isSubmitting ? "#cccccc" : "#F75506",
                color: "white",
                fontWeight: "600",
                border: "none",
                borderRadius: "30px",
                cursor: isSubmitting ? "not-allowed" : "pointer",
              }}
            >
              {isSubmitting ? "Submitting..." : "Order Submit"}
            </button>
          </div>
        </div>
      );
    }
  };

  const Navigate = useNavigate();

  const refresh = () => {

    Navigate(0);

  }
  if (step === 3) {
    return (
      <div className="success-page" id="img">
        <h1>Successfull Order</h1>

        <img src={success} alt="" />
        <h2 onClick={refresh} style={{ cursor: 'pointer' }}>Back to home</h2>
      </div>
    );
  }

  return (
    <>
      <div style={{ width: "100%", marginBottom: "3px" }}>
        <div
          style={{
            height: "10px",
            backgroundColor: "#e0e0e0",
            position: "relative",
          }}
        >
          <div
            style={{
              width: `${(step / 2) * 100}%`,
              height: "10px",
              backgroundColor: "#2A632E",
            }}
          />
        </div>
        {/* <p style={{ textAlign: 'center', margin: '5px 0' }}>
                    Step {step} of {totalSteps}
                </p> */}
      </div>
      <div className="inner-form">{renderStep()}</div>
    </>
  );
};

export default OrderInfo;
