import React, { useEffect, useState } from "react";
import Navbar from "../Components/Navbar/Navbar";
import Logo from "../assets/images/logo.png";
import "./Dashboard.css";
import { db } from "../firebase";
import { collection, onSnapshot, updateDoc, doc, getDoc } from "firebase/firestore";
import AddDayPlace from "../Components/AddDayPlace/AddDayPlace"; // Assuming DynamicForm exists in this path

export default function Dashboard() {
  const [tableData, setTableData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [filterDate, setFilterDate] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 20;
  const [selectedOrderId, setSelectedOrderId] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false); // State to manage modal visibility

  useEffect(() => {
    const unsubscribe = onSnapshot(
      collection(db, "userOrders"),
      (snapshot) => {
        const data = snapshot.docs.map((doc) => {
          const row = doc.data();
          const dateObj = row.dateDay?.toDate();
          const formattedDate = formatDate(dateObj);

          return {
            id: doc.id,
            ...row,
            dateDay: formattedDate,
          };
        });

        setTableData(data);
        setFilteredData(data);
      },
      (error) => {
        console.error("Error fetching data: ", error);
      }
    );

    return () => unsubscribe();
  }, []);

  const formatDate = (date) => {
    const options = { year: "numeric", month: "2-digit", day: "2-digit" };
    const formattedDate = date.toLocaleDateString("en-GB", options);
    const weekday = date.toLocaleDateString("en-US", { weekday: "long" });
    return `${formattedDate} - ${weekday}`;
  };

  const handleDateChange = (e) => {
    const selectedDate = e.target.value;
    setFilterDate(selectedDate);

    if (selectedDate) {
      const filtered = tableData.filter((row) => {
        const [date] = row.dateDay.split(" - ");
        const formattedSelectedDate = new Date(selectedDate).toLocaleDateString(
          "en-GB"
        );
        return date === formattedSelectedDate;
      });
      setFilteredData(filtered);
      setCurrentPage(1);
    } else {
      setFilteredData(tableData);
    }
  };

  const handleSearchChange = (e) => {
    const query = e.target.value.toLowerCase();
    setSearchQuery(query);

    const filtered = tableData.filter((row) =>
      Object.values(row).some((value) =>
        value?.toString().toLowerCase().includes(query)
      )
    );

    setFilteredData(filtered);
    setCurrentPage(1);
  };

  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const paginatedData = filteredData.slice(indexOfFirstRow, indexOfLastRow);

  const totalPages = Math.ceil(filteredData.length / rowsPerPage);

  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage(currentPage + 1);
  };

  const handlePrevPage = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1);
  };

  const handlePageClick = (page) => {
    setCurrentPage(page);
  };

  const updateStatus = async (id, status) => {
    try {
      const orderRef = doc(db, "userOrders", id);
      const orderDoc = await getDoc(orderRef);
      if (!orderDoc.exists()) {
        console.error("No such document!");
        return;
      }
      await updateDoc(orderRef, { status: status });
      setSelectedOrderId(null);
    } catch (error) {
      console.error("Error updating status: ", error);
    }
  };

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  return (
    <>
      <Navbar admin={"admin"} />
      <div className="table-container" style={{ padding: "20px" }}>
        <div className="d-flex space-between margin-bottom">
          <div className="d-flex">
            <h3>Order Table</h3>
            <input
              type="date"
              className="date-style-input"
              value={filterDate}
              onChange={handleDateChange}
            />
            <input
              type="text"
              className="date-style-input"
              placeholder="Type"
              value={searchQuery}
              onChange={handleSearchChange}
            />
            <button
              className="login"
              onClick={() => {
                setFilteredData(tableData);
                setFilterDate("");
                setSearchQuery("");
                setCurrentPage(1);
              }}
            >
              Reset
            </button>
          </div>
          <div className="pagination-controls">
            <button
              style={{ marginLeft: "10px" }}
              onClick={toggleModal}
              className="login"
            >
              Add FreeDelivery
            </button>
            <button
              className="pagination-btn"
              onClick={handlePrevPage}
              disabled={currentPage === 1}
            >
              Previous
            </button>
            {Array.from({ length: totalPages }, (_, index) => (
              <button
                key={index + 1}
                className={`pagination-btn ${
                  currentPage === index + 1 ? "active" : ""
                }`}
                onClick={() => handlePageClick(index + 1)}
              >
                {index + 1}
              </button>
            ))}
            <button
              className="pagination-btn"
              onClick={handleNextPage}
              disabled={currentPage === totalPages}
            >
              Next
            </button>
          </div>
        </div>

        <hr />
        <table
          className="styled-table"
          cellPadding="10"
          cellSpacing="0"
          style={{ width: "100%", borderCollapse: "collapse" }}
        >
          <thead>
            <tr>
              <th>#</th>
              <th>ID</th>
              <th>Name</th>
              <th>Email</th>
              <th>Phone</th>
              <th>DateDay / Place</th>
              <th>Address</th>
              <th>2.5 Lit</th>
              <th>5 Lit</th>
              <th>Delivery Type</th>
              <th>Status</th>
              <th>Amount</th>
            </tr>
          </thead>
          <tbody>
            {paginatedData.map((row, index) => (
              <tr key={row.id || index}>
                <td>{index + 1}</td>
                <td>{row.orderId}</td>
                <td className="name-cloumn">{row.name}</td>
                <td>{row.email}</td>
                <td>{row.phone}</td>
                <td>
                  {row.dateDay} / {row.place}
                </td>
                <td>{row.address}</td>
                <td>{row.twoPointFiveLit}</td>
                <td>{row.fiveLit}</td>
                <td>{row.deliveryType}</td>
                <td>
                  {row.status === "Pending" ? (
                    <div>
                      <button
                        style={{
                          color: "white",
                          backgroundColor: "#f75506",
                          padding: "5px",
                          border: "solid 1px orange",
                          borderRadius: "10px",
                          cursor: "pointer",
                        }}
                        onClick={() => setSelectedOrderId(row.orderId)}
                      >
                        Pending
                      </button>
                      {selectedOrderId === row.orderId && (
                        <div style={{ marginTop: "5px" }}>
                          <button
                            style={{ color: "green", border: "none" }}
                            onClick={() => updateStatus(row.id, "Delivered")}
                          >
                            Delivered
                          </button>
                          <button
                            style={{
                              color: "red",
                              border: "none",
                              marginLeft: "20px",
                            }}
                            onClick={() => updateStatus(row.id, "Canceled")}
                          >
                            Canceled
                          </button>
                        </div>
                      )}
                    </div>
                  ) : (
                    <span
                      style={{
                        color:
                          row.status === "Delivered"
                            ? "green"
                            : row.status === "Canceled"
                            ? "red"
                            : "black",
                      }}
                    >
                      {row.status}
                    </span>
                  )}
                </td>
                <td>{row.amount}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Modal */}
      {isModalOpen && (
        <div className="modal-overlay-delivery">
          <div  className="modal-outer-delivery">
          <img src={Logo} alt="logo"></img>
          <div className="modal-content-delivery">
          <button onClick={toggleModal} className="close-modal">
            &times;
            </button>
            <AddDayPlace />
            
          </div>
          </div>
          
        </div>
      )}
    </>
  );
}
