import "./LoginModel.css";
import Logo from "../../assets/images/logo.png";
import { useState } from "react";
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "../../firebase";
import { useNavigate } from "react-router-dom";

export default function LoginModel({ setIsModelOpen }) {
  const navigate = useNavigate();
  const [data, setData] = useState({
    email: "",
    password: "",
  });
  const [error, setError] = useState("");

  function modelClose() {
    setIsModelOpen(false);
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault(); // Prevent form reload
    setError(""); // Clear previous errors

    try {
      const userCredential = await signInWithEmailAndPassword(
        auth,
        data.email,
        data.password
      );
      console.log("User signed in successfully");

      // Get the access token from the signed-in user
      const user = userCredential.user;
      const token = await user.getIdToken();
      localStorage.setItem("accessToken", token); // Store token in localStorage

      navigate("/dashboard"); // Navigate to the dashboard
      modelClose(); // Close the modal after successful login
    } catch (err) {
      setError("Invalid email or password"); // Display error to the user
      console.error("Error signing in:", err);
    }
  };


  // useEffect(() => {
  //   onAuthStateChanged(auth, (data) => {
  //     console.log(data)

  //   })
  // },[])

  return (
    <>
      <div className="modal-overlay">
        <div className="modal-outer-container">
          <img src={Logo} alt="logo"></img>
          <div className="modal-content">
            <button className="close-btn" onClick={modelClose}>
              &times;
            </button>
            <form className="login-form" onSubmit={handleSubmit}>
              <label htmlFor="email">Email ID</label>
              <input
                type="email"
                id="email"
                name="email"
                placeholder="Enter your email"
                value={data.email}
                onChange={handleChange}
                required
              />
              <label htmlFor="password">Password</label>
              <input
                type="password"
                id="password"
                name="password"
                placeholder="Enter your password"
                value={data.password}
                onChange={handleChange}
                required
              />
              {error && <p className="error-message">{error}</p>}
              <button type="submit" className="login">
                Login
              </button>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}
