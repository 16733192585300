import React from "react";
import { Navigate } from "react-router-dom";

const PrivateRoute = ({ children }) => {
  const token = localStorage.getItem("accessToken");

  // If no token, redirect to the login page
  if (!token) {
    return <Navigate to="/" />;
  }

  // If token exists, render the protected content
  return children;
};

export default PrivateRoute;
