import Navbar from "../Components/Navbar/Navbar";
import Header from "../Components/Header/Header";
import Banner from "../Components/Banner/Banner";
// Place all the css style related to this below className in Home.css file
import "./Home.css";

export default function Home() {
  return (
    <>
      <Navbar />
      <Header />
      <Banner />
    </>
  );
}
 
// function Header() {
//   return (
//     <header>
//       <h3>Free delivery (7am to 10am)</h3>
//       <br />
//       <h3>
//         <span>Thursday</span> : Mississauga & Etobicoke |<span>Friday</span> :
//         Pickering & Ajax |<span> Saturday </span> : North York & Vaughan |{" "}
//         <span>Sunday</span> : Scarborough & Markham
//       </h3>
//     </header>
//   );
// }

// function Section() {
//   return (
//     <>
//       <div className="container">
//         <div className="row1">
//           <div className="big-line"></div>
//           <div className="small-line"></div>
//         </div>

//         <div className="row2">
//           <h1>Nalan Batters</h1>
//           <h2>Fresh Made Everyday</h2>
//           <p>
//             <i className="ri-whatsapp-fill"></i> 437 245 4049
//           </p>
//           <p>
//             <i className="ri-mail-fill"></i> nalanbatters@gmail.com
//           </p>
//           <img src={parcelImg} alt="packing" />
//           <div>
//             <button className="c-btn">Contact</button>
//             <button className="buy-btn">
//               <i className="ri-shopping-cart-2-fill"></i> Buy the batters
//             </button>
//           </div>
//         </div>

//         <div className="row3">
//           <img src={bannerImg} alt="bannerimage1" />
//           <img src={bImg} alt="bannerimage2" />
//         </div>
//       </div>

//       <div className="side-logo">
//         <img src={sideImg} alt="" />
//       </div>
//     </>
//   );
// }
