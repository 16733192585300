import { Route, Routes } from "react-router-dom";
import "./App.css"
import Home from "./Home/Home";
import Dashboard from "./Dashboard/Dashboard";
import PrivateRoute from "./Components/PrivateRoute/PrivateRoute";

export default function App() {
  return (
    <>
      <Routes>
        <Route element={<Home />} path="/" />
        <Route
          path="/dashboard"
          element={
            <PrivateRoute>
              <Dashboard />
            </PrivateRoute>
          }
        />
      </Routes>
    </>
  );
}
