import React, { useState, useEffect } from "react";
import "./AddDayPlace.css";
import { doc, getDoc, setDoc } from "firebase/firestore";
import { db } from "../../firebase"; // Adjust the path to your firebase.js

const AddDayPlace = () => {
    const [schedule, setSchedule] = useState([]);
    const [day, setDay] = useState("");
    const [place, setPlace] = useState("");
    const [message, setMessage] = useState("");
    const [isSaving, setIsSaving] = useState(false); // New state to track saving
    const [reloadKey, setReloadKey] = useState(0); // Key to trigger re-render

    // Fetch existing data on component mount or when reloadKey changes
    useEffect(() => {
        const fetchSchedule = async () => {
            try {
                const docRef = doc(db, "freeDelivery", "header");
                const docSnap = await getDoc(docRef);

                if (docSnap.exists()) {
                    const data = docSnap.data();
                    // Convert the object into an array for easier manipulation
                    const fetchedSchedule = Object.entries(data).map(([day, places]) => ({
                        day,
                        place: places,
                    }));
                    setSchedule(fetchedSchedule);
                }
            } catch (error) {
                console.error("Error fetching schedule:", error);
            }
        };

        fetchSchedule();
    }, [reloadKey]); // Reload component when reloadKey changes

    const toTitleCase = (str) => {
        return str
            .toLowerCase()
            .replace(/\b\w/g, (char) => char.toUpperCase());
    };

    const handleAdd = (e) => {
        e.preventDefault();
        if (day && place) {
            // Convert the day and place to title case
            const formattedDay = toTitleCase(day.trim());
            const formattedPlaces = place
                .split(",")
                .map((p) => toTitleCase(p.trim())) // Capitalize each place
                .filter((p) => p); // Remove empty values

            setSchedule((prev) => {
                // Check if the day already exists in the schedule
                const existingDay = prev.find(
                    (item) => item.day.trim().toLowerCase() === formattedDay.toLowerCase()
                );

                if (existingDay) {
                    // Update existing day's places
                    const currentPlaces = existingDay.place.split(", ").map((p) => p.trim());
                    const updatedPlaces = Array.from(
                        new Set([...currentPlaces, ...formattedPlaces])
                    ); // Ensure no duplicates
                    return prev.map((item) =>
                        item.day.trim().toLowerCase() === formattedDay.toLowerCase()
                            ? { day: item.day, place: updatedPlaces.join(", ") }
                            : item
                    );
                } else {
                    // Add a new day with places
                    return [...prev, { day: formattedDay, place: formattedPlaces.join(", ") }];
                }
            });
            setDay("");
            setPlace("");
        }
    };


    const handleDeleteDay = (dayToDelete) => {
        setSchedule((prev) =>
            prev.filter((item) => item.day.trim().toLowerCase() !== dayToDelete.trim().toLowerCase())
        );
    };

    const handleDeletePlace = (dayToDelete, placeToDelete) => {
        setSchedule((prev) =>
            prev
                .map((item) => {
                    if (item.day.trim().toLowerCase() === dayToDelete.trim().toLowerCase()) {
                        const places = item.place
                            .split(", ")
                            .map((p) => p.trim())
                            .filter((p) => p.toLowerCase() !== placeToDelete.trim().toLowerCase());
                        return places.length ? { day: item.day, place: places.join(", ") } : null;
                    }
                    return item;
                })
                .filter(Boolean) // Remove null entries
        );
    };

    const handleSave = async () => {
        setIsSaving(true); // Set saving state
        setMessage(""); // Clear any previous message
        try {
            const docRef = doc(db, "freeDelivery", "header");
            const updatedSchedule = {};

            schedule.forEach(({ day, place }) => {
                updatedSchedule[day] = place;
            });

            // Save updated schedule back to Firestore
            await setDoc(docRef, updatedSchedule);
            setMessage("Schedule updated successfully!");
        } catch (error) {
            console.error("Error updating schedule:", error);
            setMessage("Failed to update schedule.");
        } finally {
            setIsSaving(false); // Reset saving state
            setReloadKey((prev) => prev + 1); // Trigger reload

            // Hide message after 2 seconds
            setTimeout(() => {
                setMessage("");
            }, 5000);
        }
    };

    return (
        <div className="dynamic-form">
            <h2>Add or Update Delivery Schedule</h2>
            <form onSubmit={handleAdd}>
                <div>
                    <label htmlFor="day">Day:</label>
                    <input
                        type="text"
                        id="day"
                        value={day}
                        onChange={(e) => setDay(e.target.value)}
                        placeholder="Enter day (e.g., Monday)"
                        required
                    />
                </div>
                <div>
                    <label htmlFor="place">Place:</label>
                    <input
                        type="text"
                        id="place"
                        value={place}
                        onChange={(e) => setPlace(e.target.value)}
                        placeholder="Enter places (e.g., Toronto, Brampton)"
                        required
                    />
                </div>
                <button type="submit" className="form-btn">
                    Add
                </button>
            </form>

            <h3>Schedule Preview</h3>
            <table className="schedule">
                <thead>
                    <tr>
                        <th>Day</th>
                        <th>Places</th>
                    </tr>
                </thead>
                <tbody>
                    {schedule.map((item, index) => (
                        <tr key={index}>
                            <td>
                                <button
                                    onClick={() => handleDeleteDay(item.day)}
                                    style={{ marginRight: "10px", color: "red" }}
                                >
                                    Delete <span style={{ color: "green" }}>{item.day}</span>
                                </button>
                            </td>
                            <td>
                                {item.place.split(", ").map((p, i) => (
                                    <span key={i} style={{ marginRight: "10px" }}>
                                        <button
                                            onClick={() => handleDeletePlace(item.day, p)}
                                            style={{ color: "red" }}
                                        >
                                            Delete <span style={{ color: "green" }}>{p}</span>
                                        </button>
                                    </span>
                                ))}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>

            <button
                onClick={handleSave}
                className="form-btn"
                disabled={!schedule.length || isSaving}
            >
                {isSaving ? "Saving..." : "Save Schedule"}
            </button>

            {message && <p>{message}</p>}
        </div>
    );
};

export default AddDayPlace;
